<template>
  <div class="tfw-course-list">
    <div class="text-right">
      <CButton color="primary" :to="backURL">Back</CButton>
    </div>
    <CRow class="mt-2">
      <CCol md="4" v-for="item of courseList" :key="item.course_id">
        <CCard class="course-card">
          <img class="img-course" :src="item.thumbnail" />
          <CCardBody class="p-2 position-relative h-80">
            <div>
              <h6 class="text-2-truncate slc-title px-1" :title="item.name">
                {{ item.name }}
              </h6>
            </div>
            <div class="course-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                class="px-1 btn-course"
                :to="`/tfw/course/${item.course_id}`"
                >View Course</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CourseInfoVue from "@/containers/TFW/LandingPage/CourseInfo.vue";
import { CONSTANT } from "@/helpers/helper";
import { getScope, Role, appendAccessToken } from "@/helpers/helper"; 
export default {
  components: {
    CourseInfoVue,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters([
      "noMoreDataFromCourseList",
      "isFetchingCourseList",
      "courseTemplate",
    ]),
    courseList() {
      const courses = CONSTANT.courses;
      return courses || [];
    },
    isCandidateRole(){
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    backURL() {
      const historyBack = this.$router?.history?._startLocation
      return this.isCandidateRole && historyBack ? historyBack: "/tfw"
    }
  },
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.img-course{
  height: 200px;
}


.course-card {
  min-height: 250px;
}

.course-footer {
  bottom: 5px;
}
.h-80{
  height: 90px;
}
</style>
